
import { defineComponent } from 'vue';
import { clickElement } from '@/services/dom-tools';

export default defineComponent({
  name: 'MessageBox',
  emits: ['validate', 'cancel'],
  props: {
    id: {
      type: String,
      required: true
    },
    buttons: {
      type: String,
      required: true,
      default: 'OK'
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
  },

  computed: {
    modalId(): string { return this.id; },
    modalTarget(): string { return '#' + this.id; },
    modalShowButtonId(): string { return this.id + 'ShowButton'; },
    modalHideButtonId(): string { return this.id + 'HideButton'; },
    showOKButton(): boolean { return this.buttons.indexOf('OK') > -1; },
    showCancelButton(): boolean { return this.buttons.indexOf('Cancel') > -1; },
    showDeleteButton(): boolean { return this.buttons.indexOf('Delete') > -1; },
  },

  methods: {
    onValidate() {
      clickElement(this.modalHideButtonId);
      this.$emit('validate');
    },

    onCancel() {
      clickElement(this.modalHideButtonId);
      this.$emit('cancel');
    },
  },
});
