<template>
  <div>
    <MessageBox
      id="confirmDeleteSession"
      buttons="DeleteCancel"
      title="Confirm deletion"
      message="Are you sure you want to delete the presentation session?"
      @validate="onDeleteValidate" />

    <MessageBox
      id="confirmSessionLink"
      buttons="OK"
      title="Presentation session link"
      v-bind:message="sessionLinkMessage" />

    <div class="card">
      <div class="card-header">
        <span v-if="editMode">Edit presentation session</span>
        <span v-else>Add presentation session</span>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <label for="project">Project</label>
            <div v-if="item.projectId > 0" class="d-flex align-items-center" style="min-height: 38px;"><strong>{{ projectName }}</strong></div>
            <select v-else v-model.number="projectId" class="form-control" id="project">
              <option v-for="project in projectsList" v-bind:key="project.id" :value="project.id">
                {{ project.title }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="title">Title</label>
            <input v-model.trim="title" type="text" class="form-control" id="title" placeholder="Enter title">
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea v-model.trim="description" class="form-control" id="description" rows="3"></textarea>
          </div>
          <div class="form-group">
            <label for="startDate">Start date</label>
            <DatePicker v-model="startDate" :config="datePickerOptions" class="form-control date-picker" id="startDate" readonly="true"></DatePicker>
          </div>
          <div class="form-group">
            <label for="endDate">End date</label>
            <DatePicker v-model="endDate" :config="datePickerOptions" class="form-control date-picker" id="endDate" readonly="true"></DatePicker>
          </div>
          <div class="form-group">
            <label for="sessionJoinToken">Session join token</label>
            <div class="input-group">
              <input v-bind:value="item.sessionJoinToken" type="text" class="form-control" id="sessionJoinToken" readonly="true">
              <div class="input-group-append">
                <button type="button" class="btn btn-info" @click.prevent="onGetSessionLink()" v-bind:disabled="!hasJoinToken">Get link</button>
              </div>
            </div>
          </div>

          <HttpExtendedResultAlert v-if="error" v-bind:result="error" />

          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-primary" @click.prevent="onSave()" v-bind:disabled="saveDisabled">
              <font-awesome-icon v-if="loading" icon="spinner" spin />
              Save
            </button>
            <button v-if="hasJoinToken" type="button" class="btn btn-info" @click.prevent="onSessionStartClicked()" v-bind:disabled="loading">Start session</button>
            <button v-if="editMode" type="button" class="btn btn-danger" @click.prevent="onDelete()" v-bind:disabled="loading">Delete</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { PresentationSessionModel } from '@/models/presentation-session-model';
import { ProjectModel } from '@/models/project-model';
import { ListCardItem } from '@/models/list-card-item';
import MessageBox from '@/components/MessageBox.vue';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';
import DatePicker from '@/components/date-picker/component.vue';
import moment from 'moment';
import { clickElement } from '@/services/dom-tools';

export default defineComponent({
  name: 'PresentationSessionForm',
  emits: ['savedone', 'deletedone'],
  components: {
    MessageBox,
    HttpExtendedResultAlert,
    DatePicker,
  },
  props: {
    item: {
      type: PresentationSessionModel,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  data: () => ({
    projectId: 0,
    title: '',
    description: '' as string | undefined,
    startDate: moment(),
    endDate: moment(),
    sessionLinkMessage: '',

    datePickerOptions: { // See: https://getdatepicker.com/4/Options/
      format: 'DD/MM/YYYY HH:mm',
      useCurrent: false,
      showClose: true,
      sideBySide: true,
      ignoreReadonly: true,
    },
    
    loading: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  watch: {
    'item': {
      handler: function(newItem: PresentationSessionModel) {
        this.projectId = newItem.projectId;
        this.title = newItem.title;
        this.description = newItem.description;
        this.startDate = moment(newItem.startDate);
        this.endDate = moment(newItem.endDate);
        
        this.error = undefined;
      },
      //deep: true,
      immediate: true
    },

    startDate(newStartDate: moment.Moment) {
      if (newStartDate > this.endDate)
        this.endDate = newStartDate;
    },

    endDate(newEndDate: moment.Moment) {
      if (newEndDate < this.startDate)
        this.startDate = newEndDate;
    },
  },

  computed: {
    editMode(): boolean { return this.item.id > 0; },

    saveDisabled(): boolean {
      // '+' operator convert Date to Unix timastamp
      // for proper comparison between Date and Moment.
      return this.loading || (
        this.item.title == this.title &&
        this.item.description == this.description &&
        +this.item.startDate == +this.startDate.toDate() &&
        +this.item.endDate == +this.endDate.toDate()); },
    
    projectsList(): ListCardItem[] { return this.store.getters.projectsList; },

    projectName(): string {
      const project = this.getProjectById(this.projectId);      
      return project ? project.name : '(select project first)';
    },

    hasJoinToken(): boolean { return this.item.sessionJoinToken.length > 0; },
  },

  methods: {
    onSave() {
      this.loading = true;
      this.error = undefined;
      const payload = new PresentationSessionModel(
        this.item.id,
        this.projectId,
        this.title,
        this.startDate.toDate(),
        this.endDate.toDate(),
        this.item.sessionJoinToken,
        this.description);

      if (this.editMode)
        this.update(payload);
      else
        this.add(payload);
    },

    onDelete() {
      clickElement('confirmDeleteSessionShowButton');
    },

    async onDeleteValidate() {
      this.loading = true;
      this.error = undefined;

      this.store.dispatch(ActionTypes.DELETE_PRESENTATION_SESSION, this.item.id).then(
        status => this.parseDeleteStatus(status),
        error => this.parseResponse(undefined, error));
    },

    add(payload: PresentationSessionModel) {
      this.store.dispatch(ActionTypes.ADD_PRESENTATION_SESSION, payload).then(
        session => this.parseResponse(session, undefined),
        error => this.parseResponse(undefined, error));
    },

    update(payload: PresentationSessionModel) {
      this.store.dispatch(ActionTypes.UPDATE_PRESENTATION_SESSION, payload).then(
        session => this.parseResponse(session, undefined),
        error => this.parseResponse(undefined, error));
    },

    parseResponse(session?: PresentationSessionModel, error?: HttpExtendedResult) {
      this.loading = false;
      this.error = error;

      if (session)
        this.$emit('savedone', session);
    },

    parseDeleteStatus(status: boolean) {
      this.loading = false;

      if (status) {
        this.$emit('deletedone');
      }
      else {
        const result: HttpExtendedResult = { status: 500, title: 'An unknown error occurred!', isCancel: false };
        this.parseResponse(undefined, result);
      }
    },

    onGetSessionLink() {
      const route = this.$router.resolve({ name: 'Meet', query: { joinToken: this.item.sessionJoinToken }});
      const sessionLink = window.location.origin + route.href;
      navigator.clipboard.writeText(sessionLink).then(() => {
        this.sessionLinkMessage = 'Secure presentation session link was copied to clipboard.<br><br>You can also copy/paste the link below:<br><strong>' + sessionLink + '</strong>';
        clickElement('confirmSessionLinkShowButton');
      }, () => {
        alert('Failed to copy presentation session link to clipboard!');
      });
    },

    onSessionStartClicked() {
      this.$router.push({ name: 'Meet', query: { joinToken: this.item.sessionJoinToken }});
    },

    getProjectById(id: number): ProjectModel {
      return this.store.getters.getProjectById(id);
    },
  },
});
</script>

<style lang="scss" scoped>
.date-picker {
  background: white;
}
</style>
