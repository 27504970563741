<template>
  <div class="modal fade" v-bind:id="modalId" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="close" aria-label="Close" @click.prevent="onCancel">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p v-html="message"></p>
        </div>

        <div class="modal-footer">
          <button v-if="showCancelButton" type="button" class="btn btn-secondary" @click.prevent="onCancel">Cancel</button>
          <button v-if="showOKButton" type="button" class="btn btn-primary" @click.prevent="onValidate">OK</button>
          <button v-if="showDeleteButton" type="button" class="btn btn-danger" @click.prevent="onValidate">Delete</button>
          <button v-bind:id="modalHideButtonId" type="button" data-dismiss="modal" style="display: none;">Hide Modal</button>
        </div>
      </div>
    </div>
    <button hidden type="button" class="btn btn-primary" data-toggle="modal" v-bind:data-target="modalTarget" data-backdrop="static" v-bind:id="modalShowButtonId">Show Modal</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { clickElement } from '@/services/dom-tools';

export default defineComponent({
  name: 'MessageBox',
  emits: ['validate', 'cancel'],
  props: {
    id: {
      type: String,
      required: true
    },
    buttons: {
      type: String,
      required: true,
      default: 'OK'
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
  },

  computed: {
    modalId(): string { return this.id; },
    modalTarget(): string { return '#' + this.id; },
    modalShowButtonId(): string { return this.id + 'ShowButton'; },
    modalHideButtonId(): string { return this.id + 'HideButton'; },
    showOKButton(): boolean { return this.buttons.indexOf('OK') > -1; },
    showCancelButton(): boolean { return this.buttons.indexOf('Cancel') > -1; },
    showDeleteButton(): boolean { return this.buttons.indexOf('Delete') > -1; },
  },

  methods: {
    onValidate() {
      clickElement(this.modalHideButtonId);
      this.$emit('validate');
    },

    onCancel() {
      clickElement(this.modalHideButtonId);
      this.$emit('cancel');
    },
  },
});
</script>
