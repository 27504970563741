
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { PresentationSessionModel } from '@/models/presentation-session-model';
import { ProjectModel } from '@/models/project-model';
import { ListCardItem } from '@/models/list-card-item';
import MessageBox from '@/components/MessageBox.vue';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';
import DatePicker from '@/components/date-picker/component.vue';
import moment from 'moment';
import { clickElement } from '@/services/dom-tools';

export default defineComponent({
  name: 'PresentationSessionForm',
  emits: ['savedone', 'deletedone'],
  components: {
    MessageBox,
    HttpExtendedResultAlert,
    DatePicker,
  },
  props: {
    item: {
      type: PresentationSessionModel,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  data: () => ({
    projectId: 0,
    title: '',
    description: '' as string | undefined,
    startDate: moment(),
    endDate: moment(),
    sessionLinkMessage: '',

    datePickerOptions: { // See: https://getdatepicker.com/4/Options/
      format: 'DD/MM/YYYY HH:mm',
      useCurrent: false,
      showClose: true,
      sideBySide: true,
      ignoreReadonly: true,
    },
    
    loading: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  watch: {
    'item': {
      handler: function(newItem: PresentationSessionModel) {
        this.projectId = newItem.projectId;
        this.title = newItem.title;
        this.description = newItem.description;
        this.startDate = moment(newItem.startDate);
        this.endDate = moment(newItem.endDate);
        
        this.error = undefined;
      },
      //deep: true,
      immediate: true
    },

    startDate(newStartDate: moment.Moment) {
      if (newStartDate > this.endDate)
        this.endDate = newStartDate;
    },

    endDate(newEndDate: moment.Moment) {
      if (newEndDate < this.startDate)
        this.startDate = newEndDate;
    },
  },

  computed: {
    editMode(): boolean { return this.item.id > 0; },

    saveDisabled(): boolean {
      // '+' operator convert Date to Unix timastamp
      // for proper comparison between Date and Moment.
      return this.loading || (
        this.item.title == this.title &&
        this.item.description == this.description &&
        +this.item.startDate == +this.startDate.toDate() &&
        +this.item.endDate == +this.endDate.toDate()); },
    
    projectsList(): ListCardItem[] { return this.store.getters.projectsList; },

    projectName(): string {
      const project = this.getProjectById(this.projectId);      
      return project ? project.name : '(select project first)';
    },

    hasJoinToken(): boolean { return this.item.sessionJoinToken.length > 0; },
  },

  methods: {
    onSave() {
      this.loading = true;
      this.error = undefined;
      const payload = new PresentationSessionModel(
        this.item.id,
        this.projectId,
        this.title,
        this.startDate.toDate(),
        this.endDate.toDate(),
        this.item.sessionJoinToken,
        this.description);

      if (this.editMode)
        this.update(payload);
      else
        this.add(payload);
    },

    onDelete() {
      clickElement('confirmDeleteSessionShowButton');
    },

    async onDeleteValidate() {
      this.loading = true;
      this.error = undefined;

      this.store.dispatch(ActionTypes.DELETE_PRESENTATION_SESSION, this.item.id).then(
        status => this.parseDeleteStatus(status),
        error => this.parseResponse(undefined, error));
    },

    add(payload: PresentationSessionModel) {
      this.store.dispatch(ActionTypes.ADD_PRESENTATION_SESSION, payload).then(
        session => this.parseResponse(session, undefined),
        error => this.parseResponse(undefined, error));
    },

    update(payload: PresentationSessionModel) {
      this.store.dispatch(ActionTypes.UPDATE_PRESENTATION_SESSION, payload).then(
        session => this.parseResponse(session, undefined),
        error => this.parseResponse(undefined, error));
    },

    parseResponse(session?: PresentationSessionModel, error?: HttpExtendedResult) {
      this.loading = false;
      this.error = error;

      if (session)
        this.$emit('savedone', session);
    },

    parseDeleteStatus(status: boolean) {
      this.loading = false;

      if (status) {
        this.$emit('deletedone');
      }
      else {
        const result: HttpExtendedResult = { status: 500, title: 'An unknown error occurred!', isCancel: false };
        this.parseResponse(undefined, result);
      }
    },

    onGetSessionLink() {
      const route = this.$router.resolve({ name: 'Meet', query: { joinToken: this.item.sessionJoinToken }});
      const sessionLink = window.location.origin + route.href;
      navigator.clipboard.writeText(sessionLink).then(() => {
        this.sessionLinkMessage = 'Secure presentation session link was copied to clipboard.<br><br>You can also copy/paste the link below:<br><strong>' + sessionLink + '</strong>';
        clickElement('confirmSessionLinkShowButton');
      }, () => {
        alert('Failed to copy presentation session link to clipboard!');
      });
    },

    onSessionStartClicked() {
      this.$router.push({ name: 'Meet', query: { joinToken: this.item.sessionJoinToken }});
    },

    getProjectById(id: number): ProjectModel {
      return this.store.getters.getProjectById(id);
    },
  },
});
