<template>
  <div class="datetimepicker-inline" v-if="config.inline"></div>
  <input type="text" class="form-control" v-else>
</template>

<script>
import { defineComponent } from 'vue';
import jQuery from 'jquery';
import moment from 'moment';
import 'pc-bootstrap4-datetimepicker';
//import events from './events';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
    icons: {
      time: 'far fa-clock',
      date: 'far fa-calendar',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'fas fa-calendar-check',
      clear: 'far fa-trash-alt',
      close: 'far fa-times-circle'
    }
});

export default defineComponent({
  name: 'DatePicker',
  props: {
    modelValue: {
      default: null,
      required: true,
      validator(modelValue) {
        return modelValue === null || modelValue instanceof Date || typeof modelValue === 'string' || modelValue instanceof String || modelValue instanceof moment
      }
    },
    // http://eonasdan.github.io/bootstrap-datetimepicker/Options/
    config: {
      type: Object,
      default: () => ({})
    },
    /**
     * You can set this to true when component is wrapped in input-group
     * Note: inline and wrap mode wont work together
     */
    wrap: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      dp: null,
      // jQuery DOM
      elem: null
    };
  },
  mounted() {
    // Return early if date-picker is already loaded
    /* istanbul ignore if */
    if (this.dp) return;
    // Handle wrapped input
    this.elem = jQuery(this.wrap ? this.$el.parentNode : this.$el);
    // Init date-picker
    this.elem.datetimepicker(this.config);
    // Store data control
    this.dp = this.elem.data('DateTimePicker');
    // Set initial value
    this.dp.date(this.modelValue);
    // Watch for changes
    this.elem.on('dp.change', this.onChange);
    // Register remaining events
    this.registerEvents();
  },
  watch: {
    /**
     * Listen to change from outside of component and update DOM
     *
     * @param newValue
     */
    modelValue(newValue) {
      this.dp && this.dp.date(newValue || null)
    },

    /**
     * Watch for any change in options and set them
     *
     * @param newConfig Object
     */
    config: {
      deep: true,
      handler(newConfig) {
        this.dp && this.dp.options(newConfig);
      }
    }
  },
  methods: {
    /**
     * Update v-model upon change triggered by date-picker itself
     *
     * @param event
     */
    onChange(event) {
      //const formattedDate = event.date ? event.date.format(this.dp.format()) : null;
      //this.$emit('input', formattedDate);
      
      //this.$emit('update:modelValue', event.date.format(moment.defaultFormatUtc));
      this.$emit('update:modelValue', event.date);
    },

    /**
     * Emit all available events
     */
    registerEvents() {
      // events.forEach((name) => {
      //   this.elem.on(`dp.${name}`, (...args) => {
      //     this.$emit(`dp-${name}`, ...args);
      //   });
      // })
    }
  },
  /**
   * Free up memory
   */
  beforeUnmount() {
    /* istanbul ignore else */
    if (this.dp) {
      this.dp.destroy();
      this.dp = null;
      this.elem = null;
    }
  },
});
</script>
